import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const NavBar: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <nav className="bg-white p-4 border-b-[1px] border-gray-200">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-black font-bold text-xl">
          <Link to="/" className="text-black mr-4">
            Parkour Theory
          </Link>
        </div>
        <div className="flex items-center">
          <button onClick={handleLogout} className="text-black">
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
