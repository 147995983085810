import React, { useEffect, useState, useMemo } from 'react';
import { Canvas, extend } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import styles from './Visualizer.module.css';
import FrameTrack from '../frames/FrameTrack';
import ControlPanel from '../controls/ControlPanel';
import DraggableImage from './DraggableImage';
import { Trajectories } from './types';
import { CameraControls } from './CameraControls';
import { Scene } from './Scene';
import { Floor } from './Floor';
import { useLocation } from 'react-router-dom';
import { SecureApi } from '../../../services/Api';
import { AxiosResponse } from 'axios';

extend({ OrbitControls });

interface TrajectoryResponse {
  trajectory_url: string;
  images: string[];
}

function Visualizer() {
  const location = useLocation();
  const range = 250;
  const xMin = -1 * range;
  const xMax = range;
  const yMin = 0;
  const yMax = range;
  const zMin = -1 * range;
  const zMax = range;
  const [mode, setMode] = useState<'rotation' | 'translation'>('translation');
  const [translationStepSize, setTranslationStepSize] = useState(1.0);
  const [translation, setTranslation] = useState<[number, number, number]>([
    0, 0, 0,
  ]);
  const [rotationStepSize, setRotationStepSize] = useState(0.5);
  const [rotation, setRotation] = useState<[number, number, number]>([0, 0, 0]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [zMinTrajectory, setZMinTrajectory] = useState(0);
  const [zMaxTrajectory, setZMaxTrajectory] = useState(0);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [currentImageSrc, setCurrentImageSrc] = useState<string | null>(null);
  const [imageSources, setImageSources] = useState<string[]>([]);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [allTrajectories, setAllTrajectories] = useState<Trajectories>([]);
  const trajectories = useMemo(
    () => Array.from(allTrajectories.keys()),
    [allTrajectories]
  );
  const [selectedTrajectoryId, setSelectedTrajectoryId] = useState<number>(0);
  const [totalFrames, setTotalFrames] = useState<number>(0);
  const [isDraggableImageVisible, setIsDraggableImageVisible] = useState(true);
  const [hiddenTrajectories, setHiddenTrajectories] = useState<Set<number>>(
    new Set()
  );

  /**
   * Initializes the pose data for all trajectories and sets the minimum and maximum Z values for the
   * 3D scene based on the number of poses in the test data.
   */
  useEffect(() => {
    const fetchTrajectoryData = async () => {
      const path = location.pathname;
      const lastPart = path.split('/').pop() || '';

      try {
        const response = await SecureApi.get<AxiosResponse<TrajectoryResponse>>(
          `/v0/data/demo_trajectory/${lastPart}`,
          'FETCH_TRAJECTORY_DATA'
        );

        if (response.status !== 200) {
          throw new Error('Failed to fetch trajectory data');
        }

        const jsonResponse = await fetch(response.data.trajectory_url);

        if (!jsonResponse.ok) {
          throw new Error('Failed to fetch JSON data from the provided URL');
        }
        const trajectoryData = await jsonResponse.json();

        // Assuming the data is already in the correct format
        setAllTrajectories([trajectoryData]);
        setImageSources(response.data.images);

        if (trajectoryData.length > 0) {
          setTotalFrames(trajectoryData.length);
          // Set the minimum and maximum Z values based on the number of poses
          const gapSize = 2; // Adjust this value to change the gap size between poses
          const numPoses = trajectoryData.length;
          const zRange = (numPoses - 1) * gapSize;

          setZMinTrajectory(-zRange / 2);
          setZMaxTrajectory(zRange / 2);
        }
      } catch (error) {
        console.error('Error fetching trajectory data:', error);
      }
    };

    fetchTrajectoryData();
  }, [location.pathname]);

  /**
   * Handles the animation of the 3D scene when the 'isPlaying' state is true.
   * It updates the translation of the scene by decreasing the z-axis value
   * until it reaches the minimum trajectory value, at which point it stops
   * the animation.
   */
  useEffect(() => {
    let animationFrameId: number | null = null;

    /**
     * Animates the 3D scene by updating the translation of the scene.
     * If the z-axis translation reaches the minimum trajectory value, it stops the animation.
     */
    const animate = (): void => {
      if (isPlaying) {
        setTranslation((prev: [number, number, number]) => {
          const newTranslation: [number, number, number] = [...prev];
          newTranslation[2] -= playbackSpeed;

          if (newTranslation[2] < zMinTrajectory) {
            setIsPlaying(false);
          }

          return newTranslation;
        });
      }

      if (isPlaying) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    if (isPlaying) {
      animationFrameId = requestAnimationFrame(animate);
    }

    return () => {
      if (animationFrameId !== null) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isPlaying, zMinTrajectory]);

  /**
   * Handles the animation of the 3D scene when the 'isPlaying' state is true.
   * It updates the translation of the scene and the current frame.
   */
  useEffect(() => {
    let animationFrameId: number | null = null;
    let lastTimestamp: number | null = null;

    /**
     * Animates the 3D scene by updating the current frame and the translation of the scene.
     * If the current frame reaches the end of the pose data or the z-axis translation reaches
     * the minimum trajectory value, it stops the animation.
     */
    const animate = (timestamp: number): void => {
      if (isPlaying) {
        if (lastTimestamp === null) {
          lastTimestamp = timestamp;
        }

        const deltaTime = timestamp - lastTimestamp;
        const frameIncrement = (playbackSpeed * deltaTime) / 16.67; // 16.67 ms is roughly 60 FPS

        setCurrentFrame((prevFrame: number) => {
          const nextFrame: number = prevFrame + frameIncrement;
          if (nextFrame >= totalFrames) {
            setIsPlaying(false);
            return prevFrame;
          }
          return nextFrame;
        });

        setTranslation((prev: [number, number, number]) => {
          const newTranslation: [number, number, number] = [...prev];
          newTranslation[2] -= playbackSpeed * (deltaTime / 16.67);

          if (newTranslation[2] < zMinTrajectory) {
            setIsPlaying(false);
          }

          return newTranslation;
        });

        lastTimestamp = timestamp;
      }

      if (isPlaying) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    if (isPlaying) {
      animationFrameId = requestAnimationFrame(animate);
    }

    return () => {
      if (animationFrameId !== null) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isPlaying, zMinTrajectory, totalFrames, playbackSpeed]);

  /**
   * Generates a title based on the current URL path.
   * @returns {string} The formatted title.
   */
  const getTitle = (): string => {
    const path = location.pathname;
    const lastPart = path.split('/').pop() || '';
    return lastPart
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  /**
   * Handles the change of playback speed.
   * @param {number} speed - The new playback speed.
   * @returns {void}
   */
  const handlePlaybackSpeedChange = (speed: number) => {
    setPlaybackSpeed(speed);
  };

  /**
   * Toggles the visibility of a trajectory.
   * @param {number} trajectoryId - The ID of the trajectory to toggle.
   * @returns {void}
   */
  const handleToggleHide = (trajectoryId: number) => {
    setHiddenTrajectories((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(trajectoryId)) {
        newSet.delete(trajectoryId);
      } else {
        newSet.add(trajectoryId);
      }
      return newSet;
    });
  };

  /**
   * Deletes a trajectory.
   * @param {number} trajectoryId - The ID of the trajectory to delete.
   * @returns {void}
   */
  const handleDelete = (trajectoryId: number) => {
    setAllTrajectories((prev) => {
      const newTrajectories = [...prev];
      newTrajectories.splice(trajectoryId, 1);
      return newTrajectories;
    });
    setHiddenTrajectories((prev) => {
      const newSet = new Set(prev);
      newSet.delete(trajectoryId);
      return newSet;
    });
    if (selectedTrajectoryId === trajectoryId) {
      setSelectedTrajectoryId(0);
    }
  };

  /**
   * Handles mode change based on keyboard input.
   * @param {React.KeyboardEvent<HTMLDivElement>} event - The keyboard event.
   * @returns {void}
   */
  const handleModeChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'r') {
      setMode('rotation');
    } else if (event.key === 't') {
      setMode('translation');
    }
  };

  /**
   * Handles translation of the 3D scene based on keyboard input.
   * @param {React.KeyboardEvent<HTMLDivElement>} event - The keyboard event.
   * @returns {void}
   */
  const handleTranslation = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      const increment =
        event.key === 'ArrowUp' ? translationStepSize : -translationStepSize;
      if (event.shiftKey) {
        setTranslation((prev) => {
          const newTranslation = [...prev] as [number, number, number];
          newTranslation[1] = Math.min(
            Math.max(newTranslation[1] + increment, yMin),
            yMax
          );
          return newTranslation;
        });
      } else {
        setTranslation((prev) => {
          const newTranslation = [...prev] as [number, number, number];
          newTranslation[2] = Math.min(
            Math.max(newTranslation[2] + increment, zMin),
            zMax
          );
          return newTranslation;
        });
      }
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      const increment =
        event.key === 'ArrowRight' ? translationStepSize : -translationStepSize;
      setTranslation((prev) => {
        const newTranslation = [...prev] as [number, number, number];
        newTranslation[0] = Math.min(
          Math.max(newTranslation[0] + increment, xMin),
          xMax
        );
        return newTranslation;
      });
    }
  };

  /**
   * Handles rotation of the 3D scene based on keyboard input.
   * @param {React.KeyboardEvent<HTMLDivElement>} event - The keyboard event.
   * @returns {void}
   */
  const handleRotation = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      const increment =
        event.key === 'ArrowUp' ? rotationStepSize : -rotationStepSize;
      if (event.shiftKey) {
        setRotation((prev) => {
          const newRotation = [...prev] as [number, number, number];
          newRotation[2] = (newRotation[2] + increment + 360) % 360;
          return newRotation;
        });
      } else {
        setRotation((prev) => {
          const newRotation = [...prev] as [number, number, number];
          newRotation[0] = (newRotation[0] + increment + 360) % 360;
          return newRotation;
        });
      }
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      const increment =
        event.key === 'ArrowRight' ? rotationStepSize : -rotationStepSize;
      setRotation((prev) => {
        const newRotation = [...prev] as [number, number, number];
        newRotation[1] = (newRotation[1] + increment + 360) % 360;
        return newRotation;
      });
    }
  };

  /**
   * Handles keyboard events to update the translation of the 3D scene.
   *
   * @param event - The keyboard event object.
   * @returns void
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    handleModeChange(event);

    if (mode === 'rotation') {
      handleRotation(event);
    } else if (mode === 'translation') {
      handleTranslation(event);
    }
  };

  /**
   * Toggles the playing state of the 3D scene visualization.
   */
  const handlePlayPause = () => {
    setIsPlaying((prevState) => !prevState);
  };

  /**
   * Handles the change of the current frame and updates the translation of the 3D scene accordingly.
   *
   * @param frame - The new current frame.
   * @returns void
   */
  const handleFrameChange = (frame: number) => {
    setCurrentFrame(frame);
    // Update the translation based on the current frame
    setTranslation((prev) => {
      const newTranslation = [...prev] as [number, number, number];
      newTranslation[2] = -frame;
      return newTranslation;
    });
  };

  const handleCurrentImageChange = (imageSrc: string | null) => {
    setCurrentImageSrc(imageSrc);
  };

  /**
   * Handles the change of the translation along a specific axis.
   *
   * @param axis - The index of the axis to update (0 for x, 1 for y, 2 for z).
   * @param value - The new value for the specified axis.
   * @returns void
   */
  const handleTranslationChange = (axis: number, value: number) => {
    setTranslation((prev) => {
      const newTranslation = [...prev] as [number, number, number];
      newTranslation[axis] = value;
      return newTranslation;
    });
  };

  /**
   * Updates the rotation angle of the 3D scene.
   * @param {number} newAngle - The new rotation angle in degrees.
   * @returns {void}
   */
  const handleRotationChange = (axis: number, value: number) => {
    setRotation((prev) => {
      const newRotation = [...prev] as [number, number, number];
      newRotation[axis] = value;
      return newRotation;
    });
  };

  /**
   * Resets the translation values to their initial state.
   */
  const handleReset = () => {
    setTranslation([0, 0, 0]);
    setCurrentFrame(0);
    setIsPlaying(false);
  };

  /**
   * Handles the click event on a data point in the 3D scene.
   * @param {number} trajectoryId - The ID of the trajectory that was clicked.
   * @returns {void}
   */
  const handlePointClick = (trajectoryId: number) => {
    setSelectedTrajectoryId(trajectoryId);
    setTotalFrames(allTrajectories[trajectoryId].length);
  };

  /**
   * Handles the selection of a trajectory from the list.
   * @param {number} trajectoryId - The ID of the selected trajectory.
   * @returns {void}
   */
  const handleSelectTrajectory = (trajectoryId: number) => {
    setSelectedTrajectoryId(trajectoryId);
    setTotalFrames(allTrajectories[trajectoryId].length);
  };

  /**
   * Toggles the visibility of the draggable image.
   * @returns {void}
   */
  const handleToggleDraggableImage = () => {
    setIsDraggableImageVisible((prev) => !prev);
  };

  return (
    <div className={styles.root} onKeyDown={handleKeyDown} tabIndex={0}>
      <div className={styles.canvasContainer}>
        <Canvas camera={{ position: [0, 2, 5], fov: 50 }}>
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} intensity={0.8} />
          <Floor />
          {allTrajectories.map(
            (trajectory, trajectoryIndex) =>
              !hiddenTrajectories.has(trajectoryIndex) &&
              trajectory.map((data, index) => (
                <Scene
                  key={`${trajectoryIndex}-${index}`}
                  data={data}
                  translation={[
                    translation[0] + trajectoryIndex, // Shift each trajectory by 20 units on the x-axis
                    translation[1],
                    translation[2] + index * 2,
                  ]}
                  rotation={rotation}
                  trajectoryId={trajectoryIndex}
                  selectedTrajectoryId={selectedTrajectoryId}
                  onPointClick={handlePointClick}
                />
              ))
          )}
          <CameraControls />
          <axesHelper args={[5]} />
          {isDraggableImageVisible && <DraggableImage src={currentImageSrc} />}
        </Canvas>
        <div className={styles.title}>{getTitle()}</div>
      </div>
      <FrameTrack
        trajectoryId={selectedTrajectoryId}
        currentFrame={currentFrame}
        totalFrames={totalFrames}
        onFrameChange={handleFrameChange}
        onCurrentImageChange={handleCurrentImageChange}
        imageSources={imageSources}
      />
      <ControlPanel
        isPlaying={isPlaying}
        onPlayPause={handlePlayPause}
        onReset={handleReset}
        trajectories={trajectories}
        hiddenTrajectories={hiddenTrajectories}
        onToggleHide={handleToggleHide}
        onDelete={handleDelete}
        onSelectTrajectory={handleSelectTrajectory}
        selectedTrajectoryId={selectedTrajectoryId}
        isHidden={hiddenTrajectories.has(selectedTrajectoryId)}
        isDraggableImageVisible={isDraggableImageVisible}
        onToggleDraggableImage={handleToggleDraggableImage}
        rotation={rotation}
        rotationStepSize={rotationStepSize}
        setRotationStepSize={setRotationStepSize}
        onRotationChange={handleRotationChange}
        translation={translation}
        translationStepSize={translationStepSize}
        setTranslationStepSize={setTranslationStepSize}
        onTranslationChange={handleTranslationChange}
        playbackSpeed={playbackSpeed}
        onPlaybackSpeedChange={handlePlaybackSpeedChange}
        xMin={xMin}
        xMax={xMax}
        yMin={yMin}
        yMax={yMax}
        zMin={zMin}
        zMax={zMax}
      />
    </div>
  );
}

export default Visualizer;
