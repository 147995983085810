import { Api } from '../services/Api';
import { User } from '@supabase/supabase-js';

declare const grecaptcha: any;

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const loadRecaptchaScript = () => {
  return new Promise<void>((resolve, reject) => {
    if (window.grecaptcha) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Failed to load reCAPTCHA script'));
    document.body.appendChild(script);
  });
};

export const executeRecaptcha = async (action: string) => {
  return new Promise<string>((resolve, reject) => {
    if (grecaptcha && grecaptcha.ready) {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: action.toUpperCase(),
          })
          .then(
            (token: string) => {
              resolve(token);
            },
            (error: any) => {
              console.error('reCAPTCHA execution failed:', error);
              reject(error);
            }
          );
      });
    } else {
      console.error('reCAPTCHA not loaded');
      reject(new Error('reCAPTCHA not loaded'));
    }
  });
};

export const verifyRecaptcha = async (token: string): Promise<boolean> => {
  try {
    const response = await Api.post(
      '/v0/auth/check-recaptcha',
      { token },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.status === 200;
  } catch (error) {
    console.error('Error verifying reCAPTCHA:', error);
    return false;
  }
};

export const verifyAccess = async (user: User): Promise<boolean> => {
  try {
    const response = await Api.post(
      '/v0/auth/check-access',
      { email: user.email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.status === 200;
  } catch (error) {
    console.error('Error verifying access:', error);
    return false;
  }
};
