import React from 'react';
import PoseCard from '../../gallery/components/PoseCard';

interface Image {
  id: string;
  src: string;
  videoName: string;
}

interface ImageGridProps {
  images: Image[];
  handleImageClick: (image: Image, index: number) => void;
}

const ImageGrid: React.FC<ImageGridProps> = ({ images, handleImageClick }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
      {images.map((image, index) => (
        <PoseCard
          key={image.id}
          image={image}
          onClick={handleImageClick}
          index={index}
        />
      ))}
    </div>
  );
};

export default ImageGrid;
