import React from 'react';
import './VideoPlayer.css';

interface VideoPlayerProps {
  videoUrl: string | null;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl }) => {
  if (!videoUrl) return null;

  return (
    <div className="mt-4 mb-8">
      <video
        controls
        muted
        className="video-player w-full max-w-3xl mx-auto overflow-hidden"
        onLoadedMetadata={(e) => {
          const video = e.target as HTMLVideoElement;
          video.volume = 0;
          video.removeAttribute('controls');
          video.setAttribute('controls', '');
        }}
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
