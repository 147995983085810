import * as THREE from 'three';
import { Pose } from './types';

/**
 * Renders a set of lines connecting the data points in the provided array, representing a human pose.
 *
 * @param {Object} props - The component props.
 * @param {DataPoint[]} props.points - The array of data points representing the human pose.
 * @returns {JSX.Element} - The rendered lines connecting the data points.
 */
export function Lines({
  points,
  isSelected,
}: {
  points: Pose;
  isSelected: boolean;
}) {
  const connections = [
    [11, 12],
    [11, 13],
    [13, 15],
    [12, 14],
    [14, 16], // Arms
    [11, 23],
    [12, 24],
    [23, 24], // Torso
    [23, 25],
    [24, 26],
    [25, 27],
    [26, 28], // Legs
    [30, 32],
    [28, 30],
    [27, 31],
    [29, 31],
    [27, 29],
    [28, 32], // Feet
    [0, 2],
    [1, 2],
    [2, 3],
    [2, 7], // Left side of face
    [0, 5],
    [4, 5],
    [5, 6],
    [5, 8], // Right side of face
    [9, 10], // Mouth
  ];
  return (
    <>
      {connections.map(([i, j], index) => {
        const start = new THREE.Vector3(points[i].x, points[i].y, points[i].z);
        const end = new THREE.Vector3(points[j].x, points[j].y, points[j].z);
        const geometry = new THREE.BufferGeometry().setFromPoints([start, end]);
        return (
          <line key={index}>
            <bufferGeometry attach="geometry" {...geometry} />
            <lineBasicMaterial
              attach="material"
              color={isSelected ? '#00ff00' : 'gray'}
            />
          </line>
        );
      })}
    </>
  );
}
