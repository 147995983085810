import React from 'react';
import { Link } from 'react-router-dom';
import './VideoCard.css'; // Add this import

interface VideoSection {
  name: string;
  thumbnailUrl: string;
  frameCount: number;
}
interface VideoCardProps {
  section: VideoSection;
  toTitleCase: (str: string) => string;
}

const VideoCard: React.FC<VideoCardProps> = ({ section, toTitleCase }) => {
  return (
    <Link to={`/move/${section.name}`} className="block">
      <div className="video-card">
        <img src={section.thumbnailUrl} alt={section.name} />
        <div className="video-card-content">
          <h2 className="video-card-title">{toTitleCase(section.name)}</h2>
          <p>Frames: {section.frameCount}</p>
        </div>
      </div>
    </Link>
  );
};

export default VideoCard;
