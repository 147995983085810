import { Text } from '@react-three/drei';

/**
 * Renders a single data point with an optional label.
 *
 * @param {Object} props - The component props.
 * @param {[number, number, number]} props.position - The 3D position of the data point.
 * @param {string} props.color - The color of the data point.
 * @param {string} props.label - The label to display next to the data point.
 * @param {number} props.index - The index of the data point.
 * @param {(index: number | null) => void} props.onHover - A callback function to handle hover events on the data point.
 * @returns {JSX.Element} - The rendered data point with optional label.
 */
export function Point({
  position,
  color,
  label,
  index,
  onHover,
  onClick,
  trajectoryId,
}: {
  position: [number, number, number];
  color: string;
  label: string;
  index: number;
  onHover: (index: number | null) => void;
  onClick: (trajectoryId: number) => void;
  trajectoryId: number;
}) {
  return (
    <>
      <mesh
        position={position}
        onPointerOver={() => onHover(index)}
        onPointerOut={() => onHover(null)}
        onClick={() => onClick(trajectoryId)}
      >
        <sphereGeometry args={[0.01, 32, 32]} />
        <meshBasicMaterial color={color} />
      </mesh>
      {label && (
        <Text
          position={[position[0] + 0.02, position[1] + 0.02, position[2]]}
          fontSize={0.05}
          color={color}
        >
          {label}
        </Text>
      )}
    </>
  );
}
