import React from 'react';

interface ControlBarProps {
  rotations: number[];
  selectedRotations: number[];
  handleRotationFilter: (rotation: number) => void;
  resetRotationFilter: () => void;
  isPlaying: boolean;
  toggleSlideshow: () => void;
  playbackSpeed: number;
  handleSpeedChange: (speed: number) => void;
  handleBatchDownload: () => void;
  handleEditorNavigation: () => void;
}

const ControlBar: React.FC<ControlBarProps> = ({
  rotations,
  selectedRotations,
  handleRotationFilter,
  resetRotationFilter,
  isPlaying,
  toggleSlideshow,
  playbackSpeed,
  handleSpeedChange,
  handleBatchDownload,
  handleEditorNavigation,
}) => {
  return (
    <div className="mb-4 flex flex-wrap items-center justify-between">
      <div className="flex flex-wrap gap-2 items-center">
        {rotations.map((rotation) => (
          <button
            key={rotation}
            onClick={() => handleRotationFilter(rotation)}
            className={`px-3 py-1 rounded ${
              selectedRotations.includes(rotation)
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
          >
            {rotation}°
          </button>
        ))}
        <button
          onClick={resetRotationFilter}
          className="p-1 rounded-full hover:bg-gray-200 transition-colors duration-200"
          title="Reset filters"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </button>
        <button
          onClick={toggleSlideshow}
          className="p-1 rounded-full hover:bg-gray-200 transition-colors duration-200"
          title={isPlaying ? 'Stop slideshow' : 'Start slideshow'}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {isPlaying ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
              />
            )}
          </svg>
        </button>
        <select
          value={playbackSpeed}
          onChange={(e) => handleSpeedChange(Number(e.target.value))}
          className="p-1 rounded border border-gray-300"
        >
          <option value={70}>0.07s</option>
          <option value={150}>0.15s</option>
          <option value={500}>0.5s</option>
          <option value={1000}>1s</option>
        </select>
      </div>
      <div className="flex items-center space-x-2">
        <button
          onClick={handleEditorNavigation}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Editor
        </button>
        <button
          onClick={handleBatchDownload}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Download All
        </button>
      </div>
    </div>
  );
};

export default ControlBar;
