import React from 'react';
import './GoogleSignInButton.css';
import { supabase } from '../../../../utils/supabase';
import { executeRecaptcha, verifyRecaptcha } from '../../../../utils/auth';

interface GoogleSignInButtonProps {
  className?: string;
  setShowGlitch: (show: boolean) => void;
  resetCursor: () => void;
  onError: (error: string) => void;
}

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = ({
  className,
  setShowGlitch,
  resetCursor,
  onError,
}) => {
  const googleLogin = async () => {
    try {
      const recaptchaToken = await executeRecaptcha('login');
      const isValidToken = await verifyRecaptcha(recaptchaToken);

      if (!isValidToken) {
        onError('Unable to sign in at this time. Please try again later.');
      }

      setShowGlitch(false);
      resetCursor();

      const { data, error: supabaseError } =
        await supabase.auth.signInWithOAuth({
          provider: 'google',
          options: {
            redirectTo: `${window.location.origin}/auth/callback`,
            skipBrowserRedirect: true,
          },
        });

      if (supabaseError) {
        console.error('Google Login Failed:', supabaseError);
        onError('Unable to sign in at this time. Please try again later.');
      } else if (data && data.url) {
        window.location.href = data.url;
      }
    } catch (error: unknown) {
      console.error('Error during login process:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
      }
      if (typeof error === 'object' && error !== null && 'response' in error) {
        console.error('Error response:', (error as any).response?.data);
      }
      onError('Unable to sign in at this time. Please try again later.');
    }
  };

  return (
    <button
      onClick={() => googleLogin()}
      className={`google-sign-in-button ${className}`}
    >
      <span className="button-text">Sign in with Google</span>
    </button>
  );
};

export default GoogleSignInButton;
