export const createCursor = () => {
  // Create and append style element
  const style = document.createElement('style');
  style.textContent = `
      * {
        cursor: none;
      }
  
      .custom-cursor {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #fff;
        position: fixed;
        pointer-events: none;
        mix-blend-mode: difference;
        animation: cursorGlitch 0.5s infinite;
      }
  
      @keyframes cursorGlitch {
        0% { transform: translate(0); }
        20% { transform: translate(-2px, 2px); }
        40% { transform: translate(-2px, -2px); }
        60% { transform: translate(2px, 2px); }
        80% { transform: translate(2px, -2px); }
        100% { transform: translate(0); }
      }
    `;
  document.head.appendChild(style);

  // Create and append cursor element
  const cursor = document.createElement('div');
  cursor.classList.add('custom-cursor');
  document.body.appendChild(cursor);

  // Update cursor position
  document.addEventListener('mousemove', (e) => {
    cursor.style.left = e.clientX + 'px';
    cursor.style.top = e.clientY + 'px';
  });

  // Return a cleanup function
  return () => {
    document.head.removeChild(style);
    document.body.removeChild(cursor);
    document.removeEventListener('mousemove', (e) => {
      cursor.style.left = e.clientX + 'px';
      cursor.style.top = e.clientY + 'px';
    });
  };
};

export const resetCursor = () => {
  // Remove any existing custom cursor styles
  const existingStyle = document.querySelector('style[data-custom-cursor]');
  if (existingStyle) {
    document.head.removeChild(existingStyle);
  }

  // Remove any existing custom cursor elements
  const existingCursor = document.querySelector('.custom-cursor');
  if (existingCursor) {
    document.body.removeChild(existingCursor);
  }

  // Reset cursor to default
  const style = document.createElement('style');
  style.setAttribute('data-custom-cursor', 'reset');
  style.textContent = `
    * {
      cursor: default !important;
    }
  `;
  document.head.appendChild(style);

  // Remove any mousemove event listeners
  document.removeEventListener('mousemove', updateCursorPosition);

  // Return a function to remove the reset style if needed
  return () => {
    document.head.removeChild(style);
  };
};

// Helper function to remove in case it was defined elsewhere
function updateCursorPosition(e) {
  // This function is intentionally left empty as it's only used for removal
}
