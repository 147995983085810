import React, { useState, useMemo } from 'react';
import * as THREE from 'three';
import { Pose } from './types';
import { Point } from './Point';
import { Lines } from './Lines';
import { bodyPartMap } from './PoseLandmarkMap';

/**
 * Renders a 3D scene with a set of data points representing a human pose.
 * The scene is translated and rotated based on the provided translation values.
 * The function also handles the highlighting of hovered points and displays their corresponding body part labels.
 *
 * @param {Object} props - The component props.
 * @param {DataPoint[]} props.data - The array of data points representing the human pose.
 * @param {[number, number, number]} props.translation - The translation values for the 3D scene.
 * @returns {JSX.Element} - The rendered 3D scene.
 */
export function Scene({
  data,
  translation,
  rotation,
  trajectoryId,
  selectedTrajectoryId,
  onPointClick,
}: {
  data: Pose;
  translation: [number, number, number];
  rotation: [number, number, number];
  trajectoryId: number;
  selectedTrajectoryId: number;
  onPointClick: (trajectoryId: number) => void;
}) {
  const [hoveredPoint, setHoveredPoint] = useState<number | null>(null);
  const { translatedAndRotatedData, center } = useMemo(() => {
    // Find the hip center (average of left and right hip)
    const leftHip = new THREE.Vector3(data[23].x, data[23].y, data[23].z);
    const rightHip = new THREE.Vector3(data[24].x, data[24].y, data[24].z);
    const hipCenter = new THREE.Vector3()
      .addVectors(leftHip, rightHip)
      .multiplyScalar(0.5);

    // Find the shoulder center (average of left and right shoulder)
    const leftShoulder = new THREE.Vector3(data[11].x, data[11].y, data[11].z);
    const rightShoulder = new THREE.Vector3(data[12].x, data[12].y, data[12].z);
    const shoulderCenter = new THREE.Vector3()
      .addVectors(leftShoulder, rightShoulder)
      .multiplyScalar(0.5);

    // Calculate the up vector (from hip center to shoulder center)
    const up = new THREE.Vector3()
      .subVectors(shoulderCenter, hipCenter)
      .normalize();

    // Calculate the forward vector (cross product of up and right)
    const right = new THREE.Vector3().subVectors(rightHip, leftHip).normalize();
    const forward = new THREE.Vector3().crossVectors(right, up).normalize();

    // Adjust all points so that the lowest point is at y=0
    const lowestPoint = Math.min(...data.map((point) => point.y));

    // Convert rotation angles to radians
    const [rotX, rotY, rotZ] = rotation.map((angle) => (angle * Math.PI) / 180);

    // Create rotation matrices
    const rotationMatrixX = new THREE.Matrix4().makeRotationX(rotX);
    const rotationMatrixY = new THREE.Matrix4().makeRotationY(rotY);
    const rotationMatrixZ = new THREE.Matrix4().makeRotationZ(rotZ);

    // Combine rotation matrices
    const rotationMatrix = new THREE.Matrix4()
      .multiply(rotationMatrixX)
      .multiply(rotationMatrixY)
      .multiply(rotationMatrixZ);

    const translatedAndRotatedData = data.map((point) => {
      const vector = new THREE.Vector3(point.x, point.y, point.z);
      vector.applyMatrix4(rotationMatrix);
      return {
        x: vector.x + translation[0],
        y: vector.y + translation[1],
        z: vector.z + translation[2],
        visibility: point.visibility,
      };
    });

    return {
      translatedAndRotatedData,
      center: new THREE.Vector3(),
      lowestPoint,
    };
  }, [data, translation, rotation]);

  return (
    <group position={center.negate()}>
      {translatedAndRotatedData.map((point, index) => (
        <Point
          key={index}
          position={[point.x, point.y, point.z]}
          color={
            [
              1, 2, 3, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31,
            ].includes(index)
              ? '#ff8c04'
              : '#1cd0d8'
          }
          label={hoveredPoint === index ? bodyPartMap[index] : ''}
          index={index}
          onHover={setHoveredPoint}
          onClick={onPointClick}
          trajectoryId={trajectoryId}
        />
      ))}
      <Lines
        points={translatedAndRotatedData}
        isSelected={selectedTrajectoryId === trajectoryId}
      />
    </group>
  );
}
