import React, { useState, useEffect } from 'react';
import { SecureApi } from '../../services/Api';
import { AxiosResponse } from 'axios';
import VideoCard from './components/VideoCard';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

interface VideoSection {
  name: string;
  thumbnailUrl: string;
  frameCount: number;
}

const Gallery: React.FC = () => {
  const [sections, setSections] = useState<VideoSection[]>([]);
  const [filteredSections, setFilteredSections] = useState<VideoSection[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalFrames, setTotalFrames] = useState<number>(0);
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response: AxiosResponse<VideoSection[]> = await SecureApi.get(
          '/v0/data/video-sections',
          'GALLERY_DATA_SECTIONS'
        );

        if (response.status !== 200) {
          logout();
          navigate('/login');
        }

        const data = response.data;

        setSections(data);
        setFilteredSections(data);
        const total = data.reduce(
          (sum: number, section: VideoSection) => sum + section.frameCount,
          0
        );
        setTotalFrames(total);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchSections();
  }, []);

  useEffect(() => {
    const filtered = sections.filter((section) =>
      toTitleCase(section.name).toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSections(filtered);
  }, [searchTerm, sections]);

  const toTitleCase = (str: string) => {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const formatNumberWithCommas = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4">
      <div className="text-left my-8">
        <h1 className="text-3xl font-bold">Parkour Poses Dataset</h1>
        <p className="mt-2">Total videos: {filteredSections.length}</p>
        <p className="mt-1">
          Total frames across all videos: {formatNumberWithCommas(totalFrames)}
        </p>
        <input
          type="text"
          placeholder="Search videos..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mt-4 p-2 border border-gray-300 rounded-md w-full"
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredSections.map((section) => (
          <VideoCard
            key={section.name}
            section={section}
            toTitleCase={toTitleCase}
          />
        ))}
      </div>
    </div>
  );
};

export default Gallery;
