import { CSSProperties } from 'react';

/**
 * Generates the CSS styles for the container of a draggable image.
 *
 * @param {CSSProperties} containerStyle - The CSS properties for the container.
 * @returns {CSSProperties} - The CSS styles for the container.
 */
export const containerStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
};

/**
 * Generates the CSS styles for a draggable image.
 *
 * @param {boolean} isActive - Whether the image is currently being dragged.
 * @param {number} x - The x-coordinate of the image's position.
 * @param {number} y - The y-coordinate of the image's position.
 * @param {number} width - The width of the image.
 * @param {number} height - The height of the image.
 * @returns {CSSProperties} - The CSS styles for the draggable image.
 */
export const imageStyle = (
  isActive: boolean,
  x: number,
  y: number,
  width: number,
  height: number
): CSSProperties => ({
  width: `${width}px`,
  height: `${height}px`,
  maxWidth: '800px',
  maxHeight: '400px',
  cursor: isActive ? 'grabbing' : 'grab',
  pointerEvents: 'auto',
  position: 'absolute',
  left: `${x}px`,
  top: `${y}px`,
  userSelect: 'none',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
});

/**
 * Generates the CSS styles for a resize handle based on the given corner.
 *
 * @param {string} corner - The corner of the resize handle ('top-left', 'top-right', 'bottom-left', 'bottom-right').
 * @returns {CSSProperties} - The CSS styles for the resize handle.
 */
export const resizeHandleStyle = (corner: string): CSSProperties => ({
  position: 'absolute',
  width: '20px',
  height: '20px',
  background: 'rgba(255, 255, 255, 0.5)',
  border: '1px solid rgba(0, 0, 0, 0.3)',
  borderRadius: '50%',
  ...(corner === 'top-left' && { top: '-10px', left: '-10px' }),
  ...(corner === 'top-right' && { top: '-10px', right: '-10px' }),
  ...(corner === 'bottom-left' && { bottom: '-10px', left: '-10px' }),
  ...(corner === 'bottom-right' && { bottom: '-10px', right: '-10px' }),
  cursor: 'nwse-resize',
});

/**
 * CSS properties to disable text selection and image dragging.
 * @type {React.CSSProperties & { [key: string]: string }}
 */
export const disableSelectStyle: React.CSSProperties = {
  userSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  pointerEvents: 'none',
};
