import './VisualizerView.css';
import Visualizer from './visualizer/Visualizer';

function VisualizerView() {
  return (
    <div className="home-view">
      <Visualizer />
    </div>
  );
}

export default VisualizerView;
