import React, { useState, useEffect } from 'react';
import GlitchText from './component/text/GlitchText';
import ErrorMessage from './component/text/ErrorMessage';
import GoogleSignInButton from './component/buttons/GoogleSignInButton';
import './component/buttons/GlitchButton.css';
import './component/buttons/DefaultButton.css';
import { createCursor, resetCursor } from '../../utils/cursor';

const Login: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [text, setText] = useState('PARKOUR THEORY');
  const languages = [
    'PARKOUR THEORY', // English
    'パルクールの理論', // Japanese
    '跑酷理论', // Chinese
    '파쿠르 이론', // Korean
    'نظرية الباركور', // Arabic
    'THÉORIE DU PARKOUR', // French
    'ТЕОРИЯ ПАРКУРА', // Russian
    'TEORIA DEL PARKOUR', // Italian
    'PARKOUR-THEORIE', // German
    'TEORÍA DEL PARKOUR', // Spanish
    'TEORIA DO PARKOUR', // Portuguese
    'PARKOUR TEORISI', // Turkish
    'TEORI PARKOUR', // Indonesian
    'TEORIA PARKOUR', // Polish
    'PARKOUR-TEORI', // Swedish
    'ΘΕΩΡΙΑ ΠΑΡΚΟΥΡ', // Greek
    'पार्कूर सिद्धांत', // Hindi
    'ТЕОРІЯ ПАРКУРУ', // Ukrainian
    'פארקור תיאוריה', // Hebrew
    'ΠΑΡΚΟΥΡ ΘΕΩΡΙΑ', // Greek (alternative)
    'ТЕОРИЯ НА ПАРКУРА', // Bulgarian
    'PARKOUR TEORIJA', // Lithuanian
    'PARKOUR TEORIJA', // Latvian
    'PARKOUR TEOORIA', // Estonian
    'ТЕОРИЈА ПАРКУРА', // Serbian
    'PARKOUR TEORISI', // Azerbaijani
    'ՊԱՐԿՈՒՐԻ ՏԵՍՈՒԹՅՈՒՆ', // Armenian
    'პარკურის თეორია', // Georgian
    'ПАРКУР ТЕОРИЯСЫ', // Kazakh
    'ПАРКУР НАЗАРИЯСИ', // Uzbek
    'ТЕОРИЯИ ПАРКУР', // Tajik
    'THÉORIE DU PARKOUR', // French (already present, but common in some African countries)
    'NADHARIA YA PARKOUR', // Swahili
    'IFILOSOFI YE-PARKOUR', // Zulu
    'TIORI PARKOUR', // Yoruba
    'NAZARIYAR PARKOUR', // Hausa
    'TEORI YA PARKOUR', // Lingala
    'THÉORIE DU PARKOUR', // Wolof (uses French)
    'THIYORI YA PARKOUR', // Shona
    'ITHIYORI YEPARKOUR', // Xhosa
    'TIORI YA PARKOUR', // Kikuyu
    'THÉORIE DU PARKOUR', // Bambara (uses French)
    'ပါသီအိုရီ', // Burmese
    'ផាគួរទ្រឹស្តី', // Khmer
    'ทฤษฎีปาร์กัวร์', // Thai
    'លទ្ធិផាគួរ', // Lao
    'Lý thuyết PARKOUR', // Vietnamese
    'பார்கூர் கோட்பாடு', // Tamil
    'പാർക്കൂർ സിദ്ധാന്തം', // Malayalam
    'పార్కూర్ సిద్ధాంతం', // Telugu
    'ಪಾರ್ಕರ್ ಸಿದ್ಧಾಂತ', // Kannada
    'পার্কুর তত্ত্ব', // Bengali
    'ପାର୍କୁର ସିଦ୍ଧାନତ', // Odia
    'પાર્કુર સિદ્ધાંત', // Gujarati
    'पार्कूर सिद्धांत', // Marathi
    'ਪਾਰਕੁਰ ਸਿਧਾਂਤ', // Punjabi
    'पार्कुर सिद्धान्त', // Nepali
    'පාකූර් න්‍යාය', // Sinhala
    'PARKOUR نظريه', // Urdu
  ];

  const [showGlitch, setShowGlitch] = useState(false);

  useEffect(() => {
    if (showGlitch) {
      const interval = setInterval(() => {
        setText(languages[Math.floor(Math.random() * languages.length)]);
      }, 2000); // Change the text every 2 seconds

      return () => clearInterval(interval);
    }
  }, [showGlitch]);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    // Track user keystrokes
    let keySequence = '';
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowGlitch(false);
        resetCursor();
      }

      keySequence += event.key;
      if (keySequence === 'parkourtheory') {
        setShowGlitch(true);
        createCursor();
        keySequence = '';
      } else if (keySequence.length > 13) {
        keySequence = '';
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    const fetchLanguages = async () => {
      /* fetch languages logic */
    };
    fetchLanguages();
  }, [languages]);

  const handleError = (errorMessage: string) => {
    setError(errorMessage);
  };

  return (
    <div
      className={`flex flex-col min-h-screen ${showGlitch ? 'bg-black' : 'bg-white'}`}
    >
      <div className="flex-grow flex flex-col justify-center items-center">
        <div className="flex flex-col items-center space-y-8">
          {showGlitch ? (
            <GlitchText text={text} />
          ) : (
            <p className="text-black text-4xl font-bold">PARKOUR THEORY</p>
          )}
          {error ? (
            <ErrorMessage message={error} />
          ) : (
            <GoogleSignInButton
              className={showGlitch ? 'glitch-button' : ''}
              setShowGlitch={setShowGlitch}
              resetCursor={resetCursor}
              onError={handleError}
            />
          )}
        </div>
      </div>
      <footer className="mt-auto py-4">
        <p className="text-gray-400 text-sm text-center">
          &copy; {currentYear} The Parkour Company. All rights reserved.{' '}
          <a href="/terms" className="underline cursor-none">
            Terms
          </a>{' '}
          |{' '}
          <a href="/privacy" className="underline cursor-none">
            Privacy Policy
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Login;
