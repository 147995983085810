import React, { useEffect, useRef, useState } from 'react';
import './GlitchText.css';

interface GlitchTextProps {
  text: string;
}

const GlitchText: React.FC<GlitchTextProps> = ({ text }) => {
  const [isHovered, setIsHovered] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.classList.add('glitch-text-animate');
    }
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <p
      className={`glitch-text ${isHovered ? 'glitch-text-animate' : ''}`}
      data-text={text}
      ref={textRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text}
    </p>
  );
};

export default GlitchText;
