import React, { useState, useEffect } from 'react';
import { BorderBeam } from '../../../components/magicui/border-beam';
import './PoseCard.css';

interface Image {
  id: string;
  src: string;
  videoName: string;
}

interface PoseCardProps {
  image: Image;
  onClick: (image: Image, index: number) => void;
  index: number;
}

const PoseCard: React.FC<PoseCardProps> = ({ image, onClick, index }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = image.src;
    img.onload = () => setIsLoading(false);
    img.onerror = () => setIsLoading(false); // Handle load errors
  }, [image.src]);

  return (
    <div className="pose-card" onClick={() => onClick(image, index)}>
      {isLoading ? (
        <div className="w-full h-full">
          <BorderBeam className="rounded-lg" />
        </div>
      ) : (
        <img src={image.src} alt={image.id} />
      )}
    </div>
  );
};

export default PoseCard;
