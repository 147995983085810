import { Grid } from '@react-three/drei';

/**
 * Renders a grid floor for the 3D scene.
 *
 * @returns {JSX.Element} - The rendered Grid component.
 */
export function Floor() {
  return (
    <Grid
      args={[20, 20]}
      cellSize={1}
      cellThickness={1}
      cellColor="#000000"
      sectionSize={5}
      sectionThickness={1.5}
      sectionColor="000000"
      fadeDistance={30}
      fadeStrength={1}
      followCamera={false}
      infiniteGrid={true}
      position={[0, 0, 0]}
      rotation={[0, 0, 0]}
    />
  );
}
